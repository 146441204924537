<template>
    <section class="hold-transition bg-img" style="background-image:url(/assets/images/bg-2.jpg)" data-overlay=5>
        <div class="container pt-3 h-p100">
            <div class="row h-p100 justify-content-sm-center align-items-center">
                <div class="col-sm-6 col-md-4">
                    <div class="card border-info text-center">
                        <div class="card-header">Recover password</div>
                        <div class="text-success" v-if="success">{{ successMessage }}</div>
                        <div class="card-body">
                            <img src="@/assets/images/ftd_logo_1.png" class="img-fluid rounded-circle w-150 mb-10">
                            <h4 class="text-center mb-20">FTD Logistics</h4>
                            <form class="form-signin" @submit.prevent="onSubmit">
                                <p class="error" v-if="error">{{errorMessage}}</p>
                                <input v-model="email" type="email" class="form-control mb-2" placeholder="Email" required autofocus>
                                <button class="btn btn-lg btn-primary btn-block mb-20" @click="resetPassword">Reset<span v-if="loading" class="spinner-border text-white"></span></button>
                            </form>
                        </div>
                    </div>
                    <router-link :to="{name: 'Login'}" class="float-right text-white">Login </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { repository } from '../../presenter'
export default {
  data () {
    return {
      email: '',
      error: false,
      errorMessage: '',
      loading: false,
      success: false,
      successMessage: ''
    }
  },
  mounted () {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', '/assets/styles/vendor_components/sweetalert/sweetalert.min.js')
    document.head.appendChild(sweetAlert)
  },
  methods: {
    async resetPassword () {
      if (this.loading) return
      this.resetData()
      const userDataValidated = this.validateUserData()
      if (!userDataValidated) return
      this.loading=true;
      const data = {
        email: this.email
      }
      const response = await repository.user.resetPassword(data)
      console.log("response for forgot is ", response)
      this.loading=false;
      if (response.success) {
        this.showSuccess(response.data)
        return
      }
      this.showError(response.data)
    },

    validateUserData() {
      if (!this.email){
        this.error=true;
        this.errorMessage='Enter a valid email';
        return false;
      }
      return true;
    },

    resetData () {
      this.error = false
      this.loading = false
    },
    showSuccess (message) {
      console.log("success message is ", message)
      this.success = true
      this.successMessage = message;
      this.email='';
    },
    showError (message) {
      this.error = true
      this.errorMessage = message
    }

  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>
